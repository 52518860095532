:root {
  --background: rgb(234, 219, 206);
  --web-color: #ffffff;
  /* --logo-background: rgb(238, 233, 230 ); */
}


.particles-bg-canvas-self{
  background: var(--background);
}
.mainPage {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 1fr 2fr 3fr 3fr 2fr 1fr;
  grid-template-rows: 50px 2fr 3fr 3fr 2fr 1fr;
}
.header {
  background-color: var(--logo-background);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 50px;
  padding: 5px;
}
a {
  color: black;
  
}
p.headerLogo {
  flex-grow: 2;
}
p.headerFiller {
  flex-grow: 8 ;
}
.headerButton{
  flex-grow: 1;
  font-size: 1.1rem;
  padding: 2px;
}
.firstView {
  grid-column-start:3;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.firstViewLogo {
  width: 15rem;
}
.pitch {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: col5;
  grid-row-start: row2;
  grid-row-end: row4;
  width: 80% ;
}
.headerLogo {
  width: 100px;
}
.shortpitch {
  font-size: 1.3rem;
  font-weight: 900;
  text-align: center;
}
.spacer {
  padding: 20px;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 2;
  grid-row-end: -1;
}
.headerFiller {
}
.mainPage > span {
  grid-column-start: col2;
  grid-column-end: col5;
  grid-row-start: row3;
  grid-row-end: row-end;

}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
